<template>
  <v-form v-model="valid" ref="formImportacao" lazy-validation>
    <v-container grid-list-sm fluid>
      <v-layout row wrap>
        <v-flex sm12>
          <v-widget title="Importação de XML">
            <div slot="widget-content">
              <v-progress-linear value="exibirProgresso" v-model="percentualCarregado"></v-progress-linear>
              <v-layout row wrap>
                <v-flex xs12 sm4>
                  <v-checkbox label="Importar Clientes" v-model="configuracao.importar_clientes"></v-checkbox>
                </v-flex>
                <v-flex xs12 sm4>
                  <v-checkbox label="Importar Produtos" v-model="configuracao.importar_produtos"></v-checkbox>
                </v-flex>
                <v-flex xs12 sm4>
                  <v-checkbox label="Importar Transportadores" v-model="configuracao.importar_transportadores"></v-checkbox>
                </v-flex>
                <v-divider></v-divider>
                <v-flex xs12 sm6>
                  <input id="inputArquivo" type="file" hidden :multiple="true" @change="carregarConteudo" :disabled="carregando">
                  <v-btn color="primary" @click="escolherArquivo()">Carregar Arquivos<v-icon>import_export</v-icon></v-btn>  
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs12 sm6 >
                  <v-btn color="primary" @click="importar()" :loading="carregando">Importar<v-icon>save</v-icon></v-btn>
                </v-flex>
              </v-layout>  
            </div>
          </v-widget>
        </v-flex>          
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>

import {mapState, mapGetters, mapActions} from 'vuex';

export default {

    data: () => ({
      valid: false,
    }),

    computed: {

      ...mapState('importacao', {
        carregando: 'carregando',
        exibirProgresso: 'exibirProgresso',
        configuracao: 'configuracao',
        arquivos: 'arquivos'
      }),

      ...mapGetters('importacao', {
        percentualCarregado: 'percentualCarregado'
      })
    },

    methods: {

      ...mapActions('importacao', {
        importar: 'importarArquivos'
      }),

      escolherArquivo(){
        document.getElementById('inputArquivo').click();
      },

      carregarConteudo(evento){

        const arquivos = evento.target.files;
        this.totalArquivos = arquivos.length;
        
        for(let index = 0; index < arquivos.length; index++){
          let arquivo = arquivos[index];

          if (arquivo) {
            var reader = new FileReader();
            var vm = this;
            reader.onload = (e) => {
              let arquivoLido = {
                  total: vm.totalArquivos,
                  conteudo: e.target.result
              }      
              vm.enviarConteudo(arquivoLido);
            }
            reader.readAsBinaryString(arquivo, 'ASCII');
          }
        }
      },

      enviarConteudo(arquivo){
        this.$store.dispatch('importacao/carregarXML', arquivo);
      }
    }

}
</script>
